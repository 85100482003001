.bg-gray-XT {
  --bg-opacity: 1;
  background-color: #2d2d2d;
  background-color: rgba(45, 45, 45, var(--bg-opacity));
}

.bg-gray-XT-button-selected {
  --bg-opacity: 1;
  background-color: #232323;
  background-color: rgba(35, 35, 35, var(--bg-opacity));
}

.hover\:bg-gray-XT-button-hover:hover {
  --bg-opacity: 1;
  background-color: #3c3c3c;
  background-color: rgba(60, 60, 60, var(--bg-opacity));
}

.focus\:bg-gray-XT-button-focus:focus {
  --bg-opacity: 1;
  background-color: #3c3c3c;
  background-color: rgba(60, 60, 60, var(--bg-opacity));
}