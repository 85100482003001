.bg-gray-X {
  --bg-opacity: 1;
  background-color: #232323;
  background-color: rgba(35, 35, 35, var(--bg-opacity));
}

.bg-gray-X-button-selected {
  --bg-opacity: 1;
  background-color: #2d2d2d;
  background-color: rgba(45, 45, 45, var(--bg-opacity));
}

.hover\:bg-gray-X-button-hover:hover {
  --bg-opacity: 1;
  background-color: #373737;
  background-color: rgba(55, 55, 55, var(--bg-opacity));
}